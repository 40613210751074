export const SITE_DETAILS_INIT_START = 'common/SITE_DETAILS_INIT_START';
export const SITE_DETAILS_INIT_COMPLETED = 'common/SITE_DETAILS_INIT_COMPLETED';
export const SITE_DETAILS_INIT_FAILED = 'common/SITE_DETAILS_INIT_FAILED';

export const RESET_COMMON_DETAILS = 'common/RESET_COMMON_DETAILS';
export const COLLAPSE_MENU = "common/COLLAPSE_MENU";
export const UPDATE_AUTH_USER = "common/UPDATE_AUTH_USER";
export const SCREEN_RESIZE = "common/SCREEN_RESIZE";

export const UPDATE_WALLET_BALANCE_START = "common/UPDATE_WALLET_BALANCE_START";
export const UPDATE_WALLET_BALANC = "common/UPDATE_WALLET_BALANC";
export const UPDATE_WALLET_BALANCE_FAILED = "common/UPDATE_WALLET_BALANCE_FAILED";


export const SET_IS_ONLINE_STREAM = "common/SET_IS_ONLINE_STREAM";
export const SET_VIEW_AS_MODAL = "common/SET_VIEW_AS_MODAL";
export const SET_UPDATE_MEDIA = "common/SET_UPDATE_MEDIA";
export const UPDATE_LIVE_STERAM_BANNER_STATE = "common/UPDATE_LIVE_STERAM_BANNER_STATE";
export const CLOSE_PROMOTION_NOTIFICATION = "common/CLOSE_PROMOTION_NOTIFICATION";
export const SITE_DETAILS_SET_IS_INITED = "common/SITE_DETAILS_SET_IS_INITED";

export const AGE_VERIFICATION_BY_ID_START = "common/AGE_VERIFICATION_BY_ID_START";
export const AGE_VERIFICATION_BY_ID_COMPLETED = "common/AGE_VERIFICATION_BY_ID_COMPLETED";
export const AGE_VERIFICATION_BY_ID_FAILED = "common/AGE_VERIFICATION_BY_ID_FAILED";
export const RESET_AGE_VERIFICATION_STATUS = "common/RESET_AGE_VERIFICATION_STATUS";
export const SET_IS_ADULT_VERIFIED = "common/SET_IS_ADULT_VERIFIED";
export const LOGOUT_START = "common/LOGOUT_START";
export const LOGOUT_COMPLETED = "common/LOGOUT_COMPLETED";
export const SET_MENU_DEFAULT_ANIMATION_CLASS = "common/SET_MENU_DEFAULT_ANIMATION_CLASS";
export const OPEN_CLOSE_PENNDING_PAYMENT_MODAL = "common/OPEN_CLOSE_PENNDING_PAYMENT_MODAL";

export const SET_IS_OFFLINE = "common/SET_IS_OFFLINE";
export const CHANGE_ORDERS_TOTAL_COUNT = "common/CHANGE_ORDERS_TOTAL_COUNT";
export const CHANGE_NEW_ORDERS_REQUESTS_COUNT = "common/CHANGE_NEW_ORDERS_REQUESTS_COUNT";
export const UPDATE_PENDING_PAYMENT_DATA = "common/UPDATE_PENDING_PAYMENT_DATA";
export const UPDATE_SITEINFO_DATA = "common/UPDATE_SITEINFO_DATA";

export const SET_SHOW_NSFW_MODAL = "common/SET_SHOW_NSFW_MODAL"
