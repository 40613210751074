import { useDispatch, useSelector } from 'react-redux';
import { history } from "sites/state/store";
import { isSiteInitedSelector, screenWidthSelector, showNSFWModalSelector, siteInfoSelector } from 'sites/state/modules/common/selectors';
import { setShowNSFWModalAction } from 'sites/state/modules/common/actions';
import ModalWrapper from 'common/components/modules/modal-wrapper';
import Router from 'sites/routes/router';
import intercomMoreInfo, { CONTACT_SUPPORT } from 'sites/utils/IntercomMoreInfo';
import classNames from 'classnames';

import './style.scss'

const NSFWContentWarningModal = () => {
   const showNSFWModal = useSelector(showNSFWModalSelector)
   const screenWidht = useSelector(screenWidthSelector)
   const siteInfo = useSelector(siteInfoSelector)
   const isSiteInited = useSelector(isSiteInitedSelector);

   const dispatch = useDispatch()
   const setShowNSFWModal = (bool) => dispatch(setShowNSFWModalAction(bool))

   if(!showNSFWModal || !isSiteInited) {
      return null
   }

   const isMobile = screenWidht <= 1024
   return (
      <ModalWrapper
         contentWidth='800px'
         isMobile={ isMobile }
         isPreventDeafault={ false }
         type='confirm'
         icon='warning'
         iconColor='warning'
         iconSize='2xl'
         title='Adult Content Warning'
         // description={ siteInfo.age_verification_description || 'This site contains adult content (NSFW). You must verify your age to see the content.' }
         // descriptionColorClass='text-secondary dark:text-secondary-dark age_verification_html_description text-start'
         buttonText='Ok, close'
         nonPrimaryColor
         buttonClassName='mt-8 shrink-0'
         primaryButtonClassName='!px-[6px]'
         textColor='secondary dark:text-secondary-dark'
         action={ () => {
            setShowNSFWModal(false)
         } }
         secondButtonTitle='Verify my age'
         secondButtonWrapperClassName='shrink-0'
         overrideSecondButtonProps={ {
            primaryColor: true,
         } }
         secondButtonClassName='!px-[6px]'
         secondButtonAction={ () => {
            history.push(Router.route('AGE_VERIFICATION').getCompiledPath())
            setShowNSFWModal(false)
         } }
         cancelText='Contact support'
         cancelButtonWrapperClassName='shrink-0'
         secondaryButtonClassName='!px-[6px] intercom-adult-content-warning'
         cancelButtonAction={ () => {
            intercomMoreInfo(CONTACT_SUPPORT, { type: 'adult-content-warning' })
         } }
         modalWrapperClassName='flex flex-col'
         className='max-h-full flex-1 overflow-y-hidden'
         withoutCloseButton={ false }
         closeBtnClassName='shrink-0'
         onCloseModal={ () => setShowNSFWModal(false) }
      >
         <div
            className={ classNames({
               'w-full max-h-full flex justify-center flex-1 overflow-y-auto mt-4': true,
               'ams-custom-scrool': !isMobile,
            }) }
         >
            <span
               className='text-major dark:text-major-dark age_verification_html_description text-start'
               dangerouslySetInnerHTML={ { __html: siteInfo.age_verification_description || 'This site contains adult content (NSFW). You must verify your age to see the content.' } }
            />

         </div>
      </ModalWrapper>
   )
}

export default NSFWContentWarningModal;
