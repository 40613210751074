const initialState = {
   isSiteInited: false,
   authUser: null,
   menu: {
      isMenuOpened: false,
      animationClass: 'default',
   },
   siteInfo: {},
   screenWidth: typeof window === 'object' ? window.innerWidth : null,
   isFetchingBalance: false,
   customPages: [],
   isFakeUser: false,
   viewAsModal: {
      isOpen: false,
      type: null,
      id: null,
   },
   promotionNotification: false,
   ageVerificationStatus: null,
   isFetchingLogout: false,
   isOpenPenndingPaymentModal: false,
   total_order_requests_count: 0,
   isOfflineMode: false,
   showNSFWModal: false,
};

export default initialState;
