import React from 'react';
import SecondModal from 'common/components/modules/information-and-policies/modal';
import PropTypes from 'prop-types';
import Router from 'sites/routes/router';
import { push } from 'connected-react-router';
import { types, RECORD_KEEPING } from './data';
import intercomMoreInfo, { MEMBER_SUPPORT } from 'sites/utils/IntercomMoreInfo';
// import { isLukespov, isRiggsfilms } from 'sites/utils/Helpers';
import { getSiteNameAbbreviatur, getSiteLink } from 'common/utils/helper';
import mmsLogoLight from 'assets/images/mymembersite-logo-light.svg'
import mmsLogoDark from 'assets/images/mymembersite-logo-dark.svg'
import { useDispatch } from 'react-redux';
import rtaLogoDark from 'assets/images/rta-logo-dark.svg'
import rtaLogoLight from 'assets/images/rta-logo-light.svg'
import classNames from 'classnames';
import { filterBadWords } from 'common/utils/filter-bad-words';


const InformationAndPolices = ({
   footerPoweredByText,
   refCode,
   openSecondModal,
   secondModalType,
   isAffiliateEnabled,
   affiliateLink,
   legalPages,
   customFooterLinks,
   showLightLogo,
   isBrandingRemoval,
   whiteLabel,
   children,
   hideInformationAndPoliciesLinks,
   isAgeVerificationRequired,
}) => {
   const dispatch = useDispatch()
   let currentInfo

   if(!!legalPages && !!legalPages?.length) {
      currentInfo = legalPages?.find(it => it.name === 'ccbill')
   }

   return (
      <div className='select-none text-sm mb-6 flex flex-col items-center px-4 dk-footer' style={ { lineHeight: '16px' } }>
         <div
            className={ classNames({
               'text-center': true,
               'mb-[13px]': !hideInformationAndPoliciesLinks,
            }) }
         >
            {
               !!(footerPoweredByText?.trim()) ? (
                  <span className='text-secondary dark:text-secondary-dark '>{footerPoweredByText} </span>
               ) : (
                  <>
                     {
                        !isBrandingRemoval && (
                           <a className='skin-primary-text hover:underline font-medium tracking-[0.001em]' target='_blank' rel='noopener noreferrer' href={ `${ getSiteLink() }?ref=rf${ refCode }` }>
                              {/* {getCapitalizeSiteName()} */}
                              <img src={ showLightLogo ? mmsLogoLight : (window?.site_dark_bg ? mmsLogoDark : mmsLogoLight) } className='w-[145px] h-5' alt='mmslogo' />
                           </a>
                        )
                     }
                  </>
               )
            }
         </div>
         {
            !hideInformationAndPoliciesLinks && (
               <>
                  <div className='w-full flex flex-wrap items-center justify-center text-placeholder text-center tracking-[0.0025em]' style={ { fontSize: '10px' } } >
                     <span className='dark:text-placeholder-dark'>Charges through <a className='underline' target='_blank' rel='noopener noreferrer nofollow' href='https://support.ccbill.com/'>CCBill</a> will appear on your statement as CCBill.com *{currentInfo?.html_content ? (isAgeVerificationRequired ? filterBadWords(currentInfo?.html_content) : currentInfo?.html_content) : getSiteNameAbbreviatur()}</span>&nbsp;{'•'}&nbsp;
                     <span className='dark:text-placeholder-dark'>Charges through <a className='underline' target='_blank' rel='noopener noreferrer nofollow' href='https://cs.segpay.com/'>SegPay</a> will appear on your statement as {getSiteNameAbbreviatur()}</span>&nbsp;{'•'}&nbsp;
                     <span className='dark:text-placeholder-dark'><a className='underline' target='_blank' rel='noopener noreferrer nofollow' href='https://epoch.com/billing_support'>Epoch.com</a> Billing Support</span>
                  </div>
                  <div className='text-placeholder flex flex-wrap text-center px-7 dark:text-placeholder-dark justify-center' style={ { lineHeight: '18px', fontSize: '10px' } }>
                     {
                        types.map((type, index) => {

                           if(type === "Affiliates" && !isAffiliateEnabled) {
                              return null
                           }

                           return <div
                              role='presentation'
                              className='cursor-pointer'
                              onClick={ () => {
                                 if(type === 'Affiliates') {
                                    window.open(affiliateLink, '_blank')
                                    return
                                 } else if(type === 'Contact Us') {
                                    dispatch(push(Router.route('CONTACT_US').getCompiledPath()));
                                 } else if(type === 'Member support') {
                                    intercomMoreInfo(MEMBER_SUPPORT)
                                 } else {
                                    openSecondModal(type)
                                 }
                              } }
                              key={ index.toString() }
                           >
                              {/*  */}
                              <span
                                 data-tooltip-content={ type === RECORD_KEEPING  ? '18 U.S.C. 2257 Record-Keeping Requirements Compliance Statement' : null }
                                 data-tooltip-id={ type === RECORD_KEEPING ? 'ams-top-tooltip' : null }
                                 className='hover:underline dark:text-placeholder-dark '
                              >{ type === RECORD_KEEPING ? ' §2257 Compliance' : type }</span> {index !== types.length - 1 && '•'}&nbsp;
                           </div>
                        })
                     }
                     {
                        customFooterLinks && customFooterLinks.map(link => {
                           return (
                              <span className='dark:text-secondary-dark '>
                                 •&nbsp;<a
                                    className='underline'
                                    target='_blank'
                                    rel='noopener noreferrer nofollow'
                                    href={ link.link }
                                 >
                                    {isAgeVerificationRequired ? filterBadWords(link.display_name) : link.display_name}
                                 </a>&nbsp;
                              </span>
                           )
                        })
                     }
                     {/* {
                     (isLukespov() || isRiggsfilms()) && (
                        <>
                           <span className='dark:text-secondary-dark '>
                              •&nbsp;<a className='underline' target='_blank' rel='noopener noreferrer nofollow' href='http://freeones.com/'>freeones.com</a>
                           </span>
                           <span className='dark:text-secondary-dark '>
                               &nbsp;•&nbsp;<a className='underline' target='_blank' rel='noopener noreferrer nofollow' href='http://babepedia.com/'>babepedia.com</a>
                           </span>
                        </>
                     )
                  } */}
                  </div>
                  <div className='mt-1'>
                     <img src={ window?.site_dark_bg ? rtaLogoDark : rtaLogoLight } className='w-[42px] h-[18px]' alt='rtalogo' />
                  </div>
                  {
                     !!secondModalType && (
                        <SecondModal
                           title={ secondModalType }
                           onClose={ () => openSecondModal(null) }
                           type='sites'
                           legalPages={ legalPages }
                           whiteLabel={ whiteLabel }
                           isAgeVerificationRequired={ isAgeVerificationRequired }
                        />
                     )
                  }
                  {children}
               </>
            )
         }
      </div>
   )
}


InformationAndPolices.propTypes = {
   footerPoweredByText: PropTypes.string,
   refCode: PropTypes.string,
   openSecondModal: PropTypes.func,
   secondModalType: PropTypes.any,
   isAffiliateEnabled: PropTypes.bool,
   affiliateLink: PropTypes.string,
   legalPages: PropTypes.array,
   customFooterLinks: PropTypes.array,
   showLightLogo: PropTypes.bool,
   isBrandingRemoval: PropTypes.bool,
   whiteLabel: PropTypes.bool,
   children: PropTypes.any,
   hideInformationAndPoliciesLinks: PropTypes.bool,
   isAgeVerificationRequired: PropTypes.bool,
}

export default InformationAndPolices;
