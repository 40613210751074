import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Button from "common/components/elements/buttons/primary";

import siteLogoDark from 'assets/images/mymembersite-logo-dark.svg'
import siteLogo from 'assets/images/site-logo-light.svg'
import pageLayoutDesktopLight from 'assets/images/404-page-layout-desktop-light.svg';
import pageLayoutMobileLight from 'assets/images/404-page-layout-mobile-light.svg';
import pageLayoutDesktopDark from 'assets/images/404-page-layout-desktop-dark.svg';
import pageLayoutMobileDark from 'assets/images/404-page-layout-mobile-dark.svg';
import constructorLogo from 'assets/images/constructor-logo-new.svg';
import drakConstructorLogo from 'assets/images/dark-constructor-logo-new.svg';
import IntercomMoreInfo, { CONTACT_SUPPORT } from "sites/utils/IntercomMoreInfo";
import { isLocalhost } from 'sites/utils/Helpers';
import { notifyAbout404Page } from "sites/api/MixApi";
import DateFormatter from "common/utils/DateFormatter";

const Page404 = ({
   isMobile,
   goTo,
}) => {
   const intervalRef = useRef(null)
   const [seconds, setSeconds] = useState(10)

   useEffect(() => {
      if(0 === seconds) {
         goTo()
      }
      intervalRef.current = setInterval(() => {
         if(seconds > 0) {
            setSeconds(seconds - 1)
         }
      }, 1000)

      return () => {
         if(intervalRef.current) {
            clearInterval(intervalRef.current)
         }
      }

   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [seconds])

   const notifyAdminAboutErrorPage = async () => {
      let locationPathname = window.location.pathname
      const domainType = document.querySelector('meta[name=domainType]').content;

      if(!isLocalhost() && 'site_name_prefix' === domainType) {
         const subdomain = document.querySelector('meta[name=subdomain]').content;
         locationPathname = locationPathname.replace(`/${ subdomain }`, '')
      }

      try {
         const payload = {
            accessed_url: locationPathname,
            timezone: DateFormatter.getUserZoneAbbr(),
            referring_url: document.querySelector('meta[name=referer]')?.content || null,
         }
         await notifyAbout404Page(payload)
      } catch (error) {
         console.log(error);
      }

   }

   useEffect(() => {

      notifyAdminAboutErrorPage()

   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])



   return (
      <div
         className='w-full h-full flex flex-col justify-between'
      >
         {/* Top logo on mobile */}
         <div
            className='w-full flex justify-center pt-8'
         >
            {
               isMobile && (
                  <img
                     alt='site-logo'
                     src={ window.site_dark_bg ? siteLogoDark : siteLogo }
                     className='h-8'
                  />
               )
            }
         </div>
         {/* Bottom layer */}
         <img
            alt='404-page'
            src={ isMobile ? (window.site_dark_bg ? pageLayoutMobileDark : pageLayoutMobileLight) : (window.site_dark_bg ? pageLayoutDesktopDark : pageLayoutDesktopLight) }
            className='w-full max-h-[15.5%] object-cover'
         />


         {/* Main page */}
         <div
            className='w-full h-full absolute top-0 left-0 right-0 bottom-0 px-8 flex justify-center items-center'
         >
            <div
               className={ classNames({
                  'flex': true,
                  'justify-between w-full max-w-[1160px] mb-[9%]': !isMobile,
                  'flex-col-reverse justify-center items-center gap-6': isMobile,
               }) }
            >
               <div
                  className={ classNames({
                     'w-full flex flex-col gap-6': true,
                     'max-w-[560px]': !isMobile,
                     'items-center': isMobile,
                  }) }
               >
                  {
                     !isMobile && (
                        <img
                           alt='site-logo'
                           src={ window.site_dark_bg ? siteLogoDark : siteLogo }
                           className='h-[43px] w-fit'
                        />
                     )
                  }
                  <div
                     className={ classNames({
                        'w-full flex flex-col': true,
                        'text-start': !isMobile,
                        'text-center': isMobile,
                     }) }
                  >
                     <span
                        className={ classNames({
                           'text-action dark:text-action-dark font-bold': true,
                           'text-[65px] leading-[78px]': !isMobile,
                           'text-[40px] leading-[48px]': isMobile,
                        }) }
                     >
                        404 Error
                     </span>
                     <span
                        className={ classNames({
                           'text-action dark:text-action-dark font-bold': true,
                           'text-[50px] leading-[60px]': !isMobile,
                           'text-[27px] leading-[32px]': isMobile,
                        }) }
                     >
                        Page not found
                     </span>
                  </div>
                  <span
                     className={ classNames({
                        'text-major dark:text-major-dark': true,
                        'text-[28px] leading-[33px]': !isMobile,
                        'text-base leading-5': isMobile,
                     }) }
                  >
                     You will be redirected in {seconds} seconds
                  </span>
                  <div
                     className='h-11'
                  >
                     <Button
                        iconPosition='left'
                        iconName='attention-outline'
                        borderRadius='large'
                        classNames='!w-fit h-full !px-5 gap-2'
                        padding={ 0 }
                        textMarginX='0'
                        text='Contact support'
                        textSize='base'
                        isDefaultActionPrimaryColor
                        primaryColor
                        onClick={ () => {
                           IntercomMoreInfo(CONTACT_SUPPORT)
                        } }
                     />
                  </div>
               </div>
               <img
                  alt='constructor logo'
                  src={ window.site_dark_bg ? drakConstructorLogo : constructorLogo }
                  className={ classNames({
                     'h-[fit-content]': true,
                     'w-[484px]': !isMobile,
                     'w-[254px]': isMobile,
                  }) }
               />
            </div>
         </div>
      </div>
   )

   // return (
   //    <div
   //       className='w-full h-full relative'
   //    >

   //       <img
   //          alt='404-page'
   //          src={ isMobile ? (window.site_dark_bg ? pageLayoutMobileDark : pageLayoutMobileLight) : (window.site_dark_bg ? pageLayoutDesktopDark : pageLayoutDesktopLight) }
   //          className='absolute bottom-0 left-0 right-0 w-full max-h-[15.5%] object-cover'
   //       />
   //    </div>
   // )
}

Page404.propTypes = {
   isMobile: PropTypes.bool,
   goTo: PropTypes.func,
}

export default Page404;
